import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useHistory } from "react-router-dom";
import "./checkout.scss";

const inputStyle = {
  fontSize: '16px',
  lineHeight: '38px',
}
const CheckoutForm = ({ clientSecret }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true)
    const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: event.target.name.value
        }
      }
    });
    console.log("paymentIntent", paymentIntent)
    console.log("error", error);
    if (error) {
      setErrorMessage(error.message);
    } else {
      history.push({ pathname: "/line/orders/success" });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <strong>カード番号</strong>
      <div className="cart-el-input mb-2">
        <CardNumberElement options={{
          style: {
            base: inputStyle,
          },
        }}/>
      </div>

      <strong>カード名義</strong>
      <input className="form-control mb-2" name="name" />

      <strong>有効期限</strong>
      <div className="cart-el-input mb-2">
        <CardExpiryElement options={{
          style: {
            base: inputStyle,
          },
        }} />
      </div>

      <strong>セキュリティコード</strong>
      <div className="cart-el-input mb-2">
        <CardCvcElement options={{
          style: {
            base: inputStyle,
          },
        }} />
      </div>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Button
        type='submit'
        disabled={loading || !stripe}
        className="bg-dark-pink border-0 mx-auto mt-4 d-block"
      >
        {loading ? '...' : ''}発注
      </Button>
    </form>
  )
};

export default CheckoutForm;