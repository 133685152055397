import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axiosClient from "../../../api/axiosClient";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY_FE);

const Payment = (props) => {
  const history = useHistory();
  const orderId = props.match.params.id;

  const [clientSecret, setClientSecret] = useState(null);
  const [paymentType, setPaymentType] = useState("in_store_charge");
  const onChangeType = (e) => {
    setPaymentType(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!orderId) return;
    axiosClient
      .post(
        `/line/api/orders/${orderId}/create_payment?payment_type=${paymentType}`,
      )
      .then((response) => {
        console.log("resonse", response);
        setClientSecret(response.client_secret);
        history.push({ pathname: "/line/orders/success" });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <header className="line-header bg-pink line-header mb-3">
        <div className="title-header lg align-items-center text-center text-white">
          決済入力
        </div>
      </header>
      <div
        className="d-flex flex-column"
        style={{ height: "calc(100vh - 56px)", margin: "0px -15px" }}
      >
        <div className="flex-grow-1 d-flex flex-column p-3">
          <div className="font-weight-bold">支払い方法</div>
          <div className="mt-4">
            <form className="d-flex flex-column">
              {/* <label className="radio-inline">
                <input type="radio" name="payment_type" value="credit_card" checked={paymentType === "credit_card"} onChange={onChangeType}/>
                <p class="ml-3">クレジットカードで事前決済</p>
              </label>
              <div style={{fontSize: 12, color: 'rgb(136 136 136)'}} className="ml-4 mb-2">こちらのお支払いは、triplaによる手配・ク<br />レジットカードによる事前決済となります。</div> */}
              <label className="radio-inline">
                <input
                  type="radio"
                  name="payment_type"
                  value="bank_transfer"
                  checked={paymentType === "bank_transfer"}
                  onChange={onChangeType}
                />
                <p className="ml-3">
                  銀行振込 (お振込口座：三菱UFJ銀行 鶴舞支店 普通
                  0267541株式会社花の美芳園）
                  <br />
                  ※お振込手数料はお客様のご負担でお願い致します。
                </p>
              </label>

              <label className="radio-inline">
                <input
                  type="radio"
                  name="payment_type"
                  value="in_store_charge"
                  checked={paymentType === "in_store_charge"}
                  onChange={onChangeType}
                />
                <p className="ml-3">店頭支払い</p>
              </label>
            </form>
          </div>
          {paymentType === "credit_card" ? (
            <>
              <div className="font-weight-bold mt-4">クレジットカード</div>
              <label className="mt-2 radio-inline">
                <input type="radio" checked />
                <p className="ml-3">新しいクレジットカードを入力</p>
              </label>
              {clientSecret && (
                <div className="mt-2">
                  <Elements stripe={stripePromise}>
                    <CheckoutForm clientSecret={clientSecret} />
                  </Elements>
                </div>
              )}
            </>
          ) : (
            <Button
              type="submit"
              onClick={onSubmit}
              className="bg-dark-pink border-0 mx-auto mt-4 d-block"
            >
              発注
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment;
