import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Layout from "./components/Layout";

import Login from "./pages/Login";
import LoginVerify from "./pages/Login/LoginVerify";
import "./style.scss";

const LineApp = () => {
  return (
    <Switch>
      <Route exact path="/" component={() => <Redirect to="/line" />} />
      {/* <Route exact path="/line/login" component={Login} /> */}
      <Route exact path="/line/verify" component={LoginVerify} />
      <Route path="/line" render={(props) => <Layout {...props} />} />
    </Switch>
  );
};

export default LineApp;
